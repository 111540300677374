import * as React from "react";
import { useState } from "react";
import { PriceType, SingleShowType } from "../api/booking";

import { BookingContextType, FormType } from "./BookingProvider.types";

export const defaultForm = {
  email: "",
  emailConfirm: "",
  name: "",
  phone: "",
  nationality: "",
  comments: "",
  wheelchair: false,
  vegan: false,
  paymentMethod: "",
  termsConditions: false,
  isCompany: false,
  promoCode: "",
  setPromoCode: () => {},
};

export const BookingContext = React.createContext<BookingContextType | null>(
  null,
);

const BookingProvider = ({ children }) => {
  const [isDuende, setIsDuende] = useState(false);
  const [gtmObject, setGtmObject] = useState(null);
  const [startDate, setStartDate] = useState<string | null>();
  const [adultTickets, setAdultTickets] = useState<number>(2);
  const [nextStepEnabled, enableNextStep] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [promoCode, setPromoCode] = useState("");
  const [drinks, setDrinks] = useState<Record<string, number>[]>([
    { unknown: 0 },
  ]);
  const [activeProduct, setActiveProduct] = useState<SingleShowType | null>(
    null,
  );
  const [priceOption, setPriceOption] = useState<PriceType | null>(null);
  const [formData, setFormData] = useState<FormType>(defaultForm);
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <BookingContext.Provider
      value={{
        isDuende,
        setIsDuende,
        activeStep,
        adultTickets,
        setAdultTickets,
        nextStepEnabled,
        enableNextStep,
        activeProduct,
        setActiveProduct,
        priceOption,
        setPriceOption,
        formData,
        setFormData,
        startDate,
        setStartDate,
        paymentMethod,
        setPaymentMethod,
        isLoading,
        setIsLoading,
        gtmObject,
        setGtmObject,
        setActiveStep,
        drinks,
        setDrinks,
        promoCode,
        setPromoCode,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
