import { Header } from "components/Header/Header";
import { BookingContent, StyledBooking, StyledLogo } from "../Booking/styles";
import { PageHeadline } from "components/typography.styles";
import { FormattedMessage } from "react-intl";
import { LangSwitcher } from "components/languageSwitcher";
import { FormError, StyledField, StyledInput } from "../Booking/Form.styles";
import { useForm } from "react-hook-form";
import { Button } from "components/button/button";
import { FlexContent } from "components/FlexContent/FlexContent";
import { Spacer } from "components/spacer";
import { useAuth } from "providers/AuthProvider";
import { HeaderContent } from "components/Header/Header.styled";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ user: string; pass: string }>({
    mode: "onBlur",
  });
  const { onLogin } = useAuth();

  return (
    <StyledBooking>
      <div>
        <Header backgroundImage={"/images/IMG_3968.jpeg"}>
          <HeaderContent>
            <FlexContent fullWidth justify="center">
              <StyledLogo src={`/images/logo-tablao.svg`} alt="logo" />
              <StyledLogo src={`/images/logo-duende.png`} alt="logo" />
            </FlexContent>
            <PageHeadline>
              <FormattedMessage defaultMessage="Login" id="AyGauy" />
            </PageHeadline>
            <LangSwitcher />
          </HeaderContent>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          <form
            onSubmit={handleSubmit(onLogin)}
            id="form"
            style={{ width: "100%" }}
          >
            <FlexContent direction="column" fullWidth>
              <StyledField>
                <label>
                  <FormattedMessage defaultMessage="Username" id="JCIgkj" />
                </label>
                <StyledInput {...register("user", { required: true })} />
                {errors.user?.type === "required" && (
                  <FormError>
                    <FormattedMessage
                      defaultMessage="This field is required"
                      id="TKmub+"
                    />
                  </FormError>
                )}
              </StyledField>
              <StyledField>
                <label>
                  <FormattedMessage defaultMessage="Password" id="5sg7KC" />
                </label>
                <StyledInput
                  {...register("pass", { required: true })}
                  type="password"
                />
                {errors.pass?.type === "required" && (
                  <FormError>
                    <FormattedMessage
                      defaultMessage="This field is required"
                      id="TKmub+"
                    />
                  </FormError>
                )}
              </StyledField>
              <Spacer mt={{ xs: 24 }} />
              <Button type="submit">
                <FormattedMessage defaultMessage="Login" id="AyGauy" />
              </Button>
            </FlexContent>
          </form>
        </BookingContent>
      </div>
    </StyledBooking>
  );
};
