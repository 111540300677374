import { Loader } from "components/loader";
import { useAuth } from "providers/AuthProvider";
import { useEffect } from "react";

export const LinkLogin = () => {
  const { onLogin } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (id) {
      localStorage.setItem("qr", "true");
      onLogin({ id: parseInt(id) });
    }
  }, [onLogin]);

  return <Loader />;
};
