const DEV_API_URL = "https://dev-api.aws.tablaocordobes.com/api";
const DEV_REDSYS_URL = "https://sis-t.redsys.es:25443/sis/realizarPago";
const DEV_HOMEPAGE_URL = "https://elduendebarcelona.com";
const DEV_DUENDE_DOMAIN = "dev.booking.elduendebarcelona.com";
const DEV_TABLAO_DOMAIN = "booking.tablaocordobes.es";
const DEV_GTM_DUENDE_AUTH = "xf9uhtuQ49uRez8Oulu3fA";
const DEV_GTM_DUENDE_PREVIEW = "env-26";

const PROD_API_URL = "https://api.aws.tablaocordobes.com/api";
const PROD_REDSYS_URL = "https://sis.redsys.es/sis/realizarPago";

const PROD_HOMEPAGE_URL = "https://elduendebarcelona.com";
const PROD_DUENDE_DOMAIN = "booking.elduendebarcelona.com";
const PROD_TABLAO_DOMAIN = "booking.tablaocordobes.es";
const PROD_GTM_DUENDE_AUTH = "8y66PW58GOMuZhwGj4UE-g";
const PROD_GTM_TABLAO = "GTM-T265SRS";
const PROD_GTM_DUENDE_PREVIEW = "env-1";

export const GTM_ID_DUENDE = "GTM-5D5KNS2K";

export const GTM_ID_TABLAO =
  process.env.REACT_APP_ENV_CHECK === "prod" ? PROD_GTM_TABLAO : "GTM-000000";

export const GTM_DUENDE_AUTH =
  process.env.REACT_APP_ENV_CHECK === "prod"
    ? PROD_GTM_DUENDE_AUTH
    : DEV_GTM_DUENDE_AUTH;

export const GTM_DUENDE_PREVIEW =
  process.env.REACT_APP_ENV_CHECK === "prod"
    ? PROD_GTM_DUENDE_PREVIEW
    : DEV_GTM_DUENDE_PREVIEW;

export const EL_DUENDE_DOMAIN =
  process.env.REACT_APP_ENV_CHECK === "prod"
    ? PROD_DUENDE_DOMAIN
    : DEV_DUENDE_DOMAIN;

export const TABLAO_DOMAIN =
  process.env.REACT_APP_ENV_CHECK === "prod"
    ? PROD_TABLAO_DOMAIN
    : DEV_TABLAO_DOMAIN;

export const API_URL =
  process.env.REACT_APP_ENV_CHECK === "prod" ? PROD_API_URL : DEV_API_URL;
export const REDSYS_URL =
  process.env.REACT_APP_ENV_CHECK === "prod" ? PROD_REDSYS_URL : DEV_REDSYS_URL;
export const HOMEPAGE_URL_DUENDE =
  process.env.REACT_APP_ENV_CHECK === "prod"
    ? PROD_HOMEPAGE_URL
    : DEV_HOMEPAGE_URL;
export const HOMEPAGE_URL_TABLAO = "https://tablaocordobes.es";
export const PUBLIC_POSTHOG_KEY =
  "phc_3e3q8awoL1jBJqUWRGQpa3vxVugZFRO2Cc83AH3UdoL";
export const PUBLIC_POSTHOG_HOST = "https://us.i.posthog.com";
