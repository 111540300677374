import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BOOKING_STEPS,
  STEP_FORM,
  STEP_SEATS,
  STEP_START,
  STEP_SUMMARY,
} from "../../consts";
import { BookingContext } from "../../providers/BookingProvider";

import { Button } from "../button/button";
import { StyledFooter } from "./styles";
import { useAuth } from "providers/AuthProvider";

export const Footer = () => {
  const {
    activeStep,
    nextStepEnabled,
    enableNextStep,
    setPaymentMethod,
    isLoading,
    isDuende,
    activeProduct,
  } = useContext(BookingContext);
  const { isQr } = useAuth();
  const { search } = useLocation();
  const navigate = useNavigate();

  const prevStep = () => {
    if (BOOKING_STEPS[activeStep] === STEP_FORM) {
      navigate({
        pathname: `/`,
        search,
      });

      return;
    }
    if (BOOKING_STEPS[activeStep] === STEP_FORM && !isDuende) {
      const nextStep = activeStep - 2;
      navigate({
        pathname: `${BOOKING_STEPS[nextStep]}`,
        search,
      });

      return;
    }
    navigate({
      pathname: `${BOOKING_STEPS[activeStep - 1]}`,
      search,
    });
  };
  const nextStep = () => {
    enableNextStep(false);

    let futureStep = activeStep + 1;

    if (BOOKING_STEPS[activeStep] === STEP_START) {
      futureStep = 3;
    }

    if (
      BOOKING_STEPS[futureStep] === STEP_SEATS &&
      activeProduct &&
      activeProduct.prices.length < 2
    ) {
      navigate({
        pathname: `${STEP_FORM}`,
        search,
      });
      return;
    }
    navigate({
      pathname: `${BOOKING_STEPS[futureStep]}`,
      search,
    });
  };

  return (
    <StyledFooter alignItems="center" flexEnd={activeStep === 0}>
      {activeStep !== 0 && (
        <Button disabled={isLoading} onClick={prevStep} variant="secondary">
          <FormattedMessage defaultMessage="back" id="yLtLwT" />
        </Button>
      )}
      {BOOKING_STEPS[activeStep] === STEP_SUMMARY ? (
        <div>
          <Button
            disabled={isLoading}
            onClick={() => setPaymentMethod("REDSYS")}
          >
            {isQr ? (
              <FormattedMessage defaultMessage="card" id="mpJrBL" />
            ) : (
              <FormattedMessage defaultMessage="book now" id="8Rk8FB" />
            )}
          </Button>
          {isQr && (
            <Button
              disabled={isLoading}
              onClick={() => setPaymentMethod("PAYPAL")}
            >
              Paypal
            </Button>
          )}
        </div>
      ) : (
        <Button disabled={!nextStepEnabled} onClick={nextStep}>
          <FormattedMessage defaultMessage="next" id="izd/bu" />
        </Button>
      )}
    </StyledFooter>
  );
};
