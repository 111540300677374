import { PriceType, SingleShowType } from "../api/booking";
import { EVENT_TYPES_PRIORITY } from "../consts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { showAndDinner } from "../i18n/content";
dayjs.extend(utc);

export type ParsedCalendarType = {
  [key: string]: {
    [key: string]: SingleShowType[];
  };
};
export const parseCalendar = ({
  data,
  guests,
}: {
  data: SingleShowType[];
  guests: number;
}): ParsedCalendarType => {
  const result = {};

  data.forEach((item) => {
    const date = item.date.substring(0, 10);
    const showTime = getShowTime(item.showTime);

    if (item.totalTicketsAvailable < guests) {
      return;
    }
    // if item.date is in the past -30 minutes, skip it
    if (
      dayjs(item.date).utc().add(1, "hour").format("YYYY-MM-DD HH:mm") <
      dayjs().add(30, "m").format("YYYY-MM-DD HH:mm")
    ) {
      return;
    }

    // if single person is not allowed for dinner, skip it
    if (
      item.eventType === showAndDinner &&
      guests === 1 &&
      !item.allowSinglePerson
    ) {
      return;
    }

    const prices = item.prices.filter(
      (price) => price.ticketsAvailable >= guests,
    );

    if (prices.length > 0) {
      if (!result[date]) {
        result[date] = {};
      }

      if (item.place === "duende") {
        if (!result[date][showTime]) {
          result[date][showTime] = [];
        }
        result[date][showTime].push({
          ...item,
          date: dayjs(item.date)
            .utc()
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          showTime,
          prices,
        });
        result[date][showTime].sort((a, b) => {
          const aPriority = EVENT_TYPES_PRIORITY.indexOf(a.eventType);
          const bPriority = EVENT_TYPES_PRIORITY.indexOf(b.eventType);
          return aPriority - bPriority;
        });
      } else {
        if (!result[date][item.eventType]) {
          result[date][item.eventType] = [];
        }

        result[date][item.eventType].push({
          ...item,
          date: dayjs(item.date)
            .utc()
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          showTime,
        });
      }
    }
  });

  // returned result should be sorted by date in ascending order
  return Object.keys(result)
    .sort()
    .reduce((acc, key) => {
      acc[key] = result[key];
      return acc;
    }, {});
};

export const findSmallestPrice = (prices: PriceType[]): PriceType => {
  let smallestPrice = prices[0];
  prices.forEach((price) => {
    if (price.price < smallestPrice.price) {
      smallestPrice = price;
    }
  });
  return smallestPrice;
};

export const getShowTime = (time: string): string =>
  dayjs()
    .set("hour", Number(time.split(":")[0]) + 1) // time from API comes in UTC+0
    .set("minute", Number(time.split(":")[1]))
    .format("HH:mm");
