import styled from "styled-components";
import Calendar from "react-calendar";
import { BookingElement, RadioButtonWrapper } from "./styles";
import { FlexContent } from "../../FlexContent/FlexContent";

export const StyledCalendarWrapper = styled.div``;

export const StyledCalendarOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.2);
`;

export const StyledCalendar = styled(Calendar)`
  font-family: ${({ theme }) => theme.fonts.sans};
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  box-shadow: 0 3px 10px #9c9c9c80;

  .react-calendar__tile--now,
  .react-calendar__tile--active {
    background: ${({ theme }) => theme.colors.yellow};
  }

  .react-calendar__navigation__label {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    padding-top: 16px;
  }

  .react-calendar__navigation__arrow {
    font-size: 34px;
  }
`;

export const CalendarEvents = styled.div`
  display: flex;
  flex-direction: column;

  ${BookingElement} {
    cursor: pointer;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: flex-start;

    ${BookingElement} {
      margin-bottom: 0;
      width: calc(100% / 3 - 11px);

      &:nth-of-type(2) {
        margin-left: 11px;
      }
      &:nth-of-type(3) {
        margin-left: 11px;
      }
    }
  }
`;

export const ShowTitle = styled(FlexContent)`
  justify-content: flex-start;
  align-items: flex-start;
`;
export const ShowPrice = styled(FlexContent)``;
export const ElementWrapper = styled(FlexContent)`
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: flex-end;

    ${RadioButtonWrapper} {
      display: none;
    }

    ${ShowPrice} {
      align-items: flex-end;
    }
  }
`;

export const DesktopRadioWrapper = styled(RadioButtonWrapper)`
  align-self: flex-end;
`;

export const EventDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  ${RadioButtonWrapper} {
    margin-left: 0;
  }

  ${ShowTitle} {
    width: 60%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: column;
    align-items: flex-start;

    ${ShowTitle} {
      width: 100%;
    }

    ${ElementWrapper} {
      width: 100%;
    }
  }
`;
