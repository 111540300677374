import { FlexContent } from "components/FlexContent/FlexContent";
import { CounterComponent } from "components/peopleCounter";
import { Spacer } from "components/spacer";
import {
  BigParagraph,
  SectionHeadline,
  SmallHeadline,
  SmallParagraph,
} from "components/typography.styles";
import { PeopleCounterWrapper } from "components/views/Booking/styles";
import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";

export const PeopleSelection = () => {
  const { adultTickets, setAdultTickets } = useContext(BookingContext);

  return (
    <>
      <SectionHeadline>
        1. <FormattedMessage defaultMessage="Number of guests" id="K+okA1" />
      </SectionHeadline>
      <BigParagraph>
        <FormattedMessage
          defaultMessage="Please select the number of guests for your reservation."
          id="z3kuhH"
        />
      </BigParagraph>
      <Spacer mt={{ xs: 16, md: 32 }} />
      <FlexContent
        mobileDirection="column"
        direction="row"
        justify="space-between"
        fullWidth
        alignItems="flex-start"
      >
        <PeopleCounterWrapper>
          <CounterComponent onClick={setAdultTickets} value={adultTickets}>
            <SmallHeadline>
              <FormattedMessage defaultMessage="Guests" id="NIVTxd" />
            </SmallHeadline>
          </CounterComponent>
        </PeopleCounterWrapper>
      </FlexContent>
      <Spacer mt={{ xs: 5, md: 40 }} />
      <SmallParagraph>
        <strong>
          <FormattedMessage
            defaultMessage="Important Information for Families with Children"
            id="koaSZo"
          />
        </strong>
        <ul>
          {/* <li>
            <FormattedMessage
              defaultMessage="Children between 4 and 8 years old will benefit from a 50% reduction
            in the ticket rate, redeemable only at check-in after showing proof
            of age."
              id="kIw44/"
            />
          </li> */}
          <li>
            <FormattedMessage
              defaultMessage="Children under 4 years old: Unfortunately, for the comfort and
            enjoyment of all guests, children under 4 years old are not
            permitted to attend the show."
              id="c82wsA"
            />
          </li>
        </ul>
      </SmallParagraph>
      <Spacer mt={{ xs: 15 }} />
      <SmallParagraph>
        <strong>
          <FormattedMessage defaultMessage="Audience Etiquette:" id="Dq/5zm" />
        </strong>
        <ul>
          <li>
            <FormattedMessage
              defaultMessage="Maintain Silence: The performance requires a quiet atmosphere for
            the best experience. "
              id="V54LWO"
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Parents and Guardians: If you are attending with children, please
            ensure they remain quiet throughout the performance. If needed, an
            adult will be asked to accompany the child out of the show hall
            until they are calm. Our staff will assist you with anything
            required."
              id="fcEK7C"
            />
          </li>
        </ul>
        <br />
        <FormattedMessage
          defaultMessage="We appreciate your understanding and cooperation in maintaining the
        immersive atmosphere of the performance."
          id="EzEfxF"
        />
      </SmallParagraph>
    </>
  );
};
