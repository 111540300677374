import styled from "styled-components";
import { FlexContent } from "./FlexContent/FlexContent";

export const PageHeadline = styled.h1`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: 700;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 32px;
  }
`;
export const StyledLogotypes = styled(FlexContent)`
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    justify-content: center;
  }
`;

export const SectionHeadline = styled.h2`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
  }
`;

export const BigHeadline = styled.h3<{
  isSerifFont?: boolean;
  isUppercase?: boolean;
}>`
  font-family: ${({ isSerifFont, theme }) =>
    isSerifFont ? theme.fonts.serif : theme.fonts.sans};
  font-size: 24px;
  text-transform: ${({ isUppercase }) => (isUppercase ? "uppercase" : "none")};
`;
export const SmallHeadline = styled(BigHeadline).attrs({
  as: "h3",
})`
  font-size: 14px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
  }
`;

export const Label = styled.label<{ fontWeight?: number }>`
  font-size: 10px;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;

export const SmallParagraph = styled.p<{ color?: string; textAlign?: string }>`
  color: ${({ color, theme }) => color || theme.colors.gray80};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: ${({ textAlign }) => textAlign || "left"};
`;

export const MediumParagraph = styled(SmallParagraph)`
  font-size: 12px;
`;

export const BigParagraph = styled(SmallParagraph)`
  font-size: 14px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
  }
`;

export const MediumLabel = styled(Label)`
  font-size: 12px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
  }
`;

export const MediumLink = styled(MediumLabel)`
  text-transform: uppercase;
  text-decoration: underline;
`;

export const AdjustLink = styled.span`
  text-decoration: underline;
  font-weight: bold;
  padding: 0 2px;
`;

export const BigLabel = styled(Label)`
  font-size: 16px;
`;

export const SummaryLabelSmall = styled.label<{ isUpperCase?: boolean }>`
  font-size: 10px;
  text-transform: ${({ isUpperCase }) => (isUpperCase ? "uppercase" : "none")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
  }
`;
