import { LanguageContext } from "providers/LangProvider";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { BOOKING_STEPS, STEP_FORM } from "../../../consts";
import { countriesAll } from "../../../consts/nationalities";
import { BookingContext } from "../../../providers/BookingProvider";
import { FormType, SelectType } from "../../../providers/BookingProvider.types";
import { FlexContent } from "../../FlexContent/FlexContent";
import { Spacer } from "../../spacer";
import {
  AdjustLink,
  MediumLabel,
  MediumParagraph,
  SectionHeadline,
} from "../../typography.styles";
import { FormError, StyledField, StyledForm, StyledInput } from "./Form.styles";
import { CheckboxButton, CheckboxContainer, CheckboxLabel } from "./styles";

export const Form = () => {
  const {
    nextStepEnabled,
    enableNextStep,
    formData,
    setFormData,
    setActiveStep,
    isDuende,
  } = useContext(BookingContext);
  const [countries, setCountries] = useState<SelectType[]>([]);

  useEffect(() => {
    setActiveStep(BOOKING_STEPS.indexOf(STEP_FORM));
  }, []);

  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    const c = countriesAll.map((country) => ({
      value: country.value,
      label: country[`label_${locale}`],
    }));
    setCountries(c);
  }, [locale]);

  const {
    register,
    formState: { isValid, errors },
    // getValues,
    setValue,
    watch,
  } = useForm<FormType>({
    mode: "onBlur",
    defaultValues: formData,
  });

  const getUrl = () => {
    if (isDuende) {
      if (["ja", "it"].includes(locale)) {
        return `https://www.elduendebarcelona.com/terms-and-conditions`;
      }
      return `https://www.elduendebarcelona.com/${locale}/terms-and-conditions`;
    }

    if (locale === "en") {
      return "https://tablaocordobes.es/legal";
    }

    return `https://tablaocordobes.es/${locale}/legal`;
  };

  const [
    email,
    emailConfirm,
    name,
    phone,
    nationality,
    comments,
    termsConditions,
    vegan,
    wheelchair,
  ] = watch(
    [
      "email",
      "emailConfirm",
      "name",
      "phone",
      "nationality",
      "comments",
      "termsConditions",
      "vegan",
      "wheelchair",
    ],
    {
      email: "",
      emailConfirm: "",
      name: "",
      phone: "",
      nationality: "",
      comments: "",
      termsConditions: false,
      vegan: false,
      wheelchair: false,
    },
  );

  useEffect(() => {
    setFormData({
      ...formData,
      email,
      emailConfirm,
      name,
      phone,
      nationality,
      comments,
      termsConditions,
      vegan,
      wheelchair,
    });
  }, [
    email,
    emailConfirm,
    name,
    phone,
    nationality,
    comments,
    termsConditions,
    vegan,
    wheelchair,
  ]);

  useEffect(() => {
    if (!isValid && nextStepEnabled) {
      enableNextStep(false);
    }
    if (isValid && nationality) {
      enableNextStep(true);
    }
  }, [isValid, nextStepEnabled, nationality]);

  const { formatMessage } = useIntl();

  return (
    <>
      <SectionHeadline>
        {isDuende ? "4. " : "3. "}
        <FormattedMessage
          defaultMessage="Complete customer information"
          id="TBrClT"
        />
      </SectionHeadline>
      <Spacer mt={{ xs: 24 }} />
      <StyledForm id="form">
        <StyledField>
          <label>
            <FormattedMessage
              defaultMessage="First and Last name"
              id="Udes+A"
            />
          </label>
          <StyledInput {...register("name", { required: true })} />
          {errors.name?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="Nationality" id="4FsZv8" />
          </label>

          <Select
            placeholder={formatMessage({
              defaultMessage: "Select your country",
              id: "EoIFbQ",
            })}
            className="react-select-container"
            defaultValue={countries.find(
              (country) => country.value === formData.nationality,
            )}
            options={countries.sort((a, b) => a.label.localeCompare(b.label))}
            classNamePrefix="react-select"
            onChange={(e: SelectType) => {
              return setValue("nationality", e.value);
            }}
          />
          {isValid && !nationality && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="E-mail address" id="kIW/Ua" />
          </label>
          <StyledInput
            {...register("email", {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {errors.email?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect email address"
                id="5o+ZyZ"
              />
            </FormError>
          )}
          {errors.email?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "sameEmail" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Email addresses do not match"
                id="DCRJgA"
              />
            </FormError>
          )}
        </StyledField>
        {/* <StyledField>
          <label>
            <FormattedMessage defaultMessage="Confirm e-mail" id="XaowG7" />
          </label>
          <StyledInput
            {...register("emailConfirm", {
              validate: {
                required: (value) => !!getValues().email || !!value,
                sameEmail: (value) => value === getValues().email,
              },
            })}
          />
          {errors.emailConfirm?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect email address"
                id="5o+ZyZ"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "sameEmail" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Email addresses do not match"
                id="DCRJgA"
              />
            </FormError>
          )}
        </StyledField> */}
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="Phone number" id="jdJhOL" />
          </label>
          <StyledInput
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9 ()+-]+$/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {errors.phone?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect phone number"
                id="zOgguc"
              />
            </FormError>
          )}
          {errors.phone?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage
              defaultMessage="Comments: Let us know any special requirements or preferences."
              id="TbItA0"
            />
          </label>
          <StyledInput {...register("comments", { maxLength: 250 })} />
          {errors.comments?.type === "maxLength" && (
            <FormError>
              <FormattedMessage defaultMessage="Too long comment" id="4bovLw" />
            </FormError>
          )}
        </StyledField>

        <FlexContent
          direction="column"
          alignItems="flex-start"
          justify="flex-start"
          fullWidth
          padding="0 0 0 8px"
        >
          <Spacer mt={{ xs: 16, md: 32 }} />

          <>
            {!isDuende && (
              <>
                <MediumLabel fontWeight={700}>
                  <FormattedMessage
                    defaultMessage="Additional needs and requests"
                    id="mZXen3"
                  />
                </MediumLabel>
                <Spacer mt={{ xs: 16, md: 24 }} />
                <CheckboxContainer alignItems="flex-start">
                  <FlexContent justify="center" alignItems="center">
                    <CheckboxButton
                      onChange={(event) =>
                        setValue("wheelchair", event.target.checked)
                      }
                      type="checkbox"
                      name="wheelchair"
                      {...register("wheelchair")}
                    />
                    <CheckboxLabel />
                  </FlexContent>
                  <MediumParagraph color="black">
                    <FormattedMessage
                      defaultMessage="Wheelchair access"
                      id="sTRKZY"
                    />
                    {/* IMPORTANT Wheelchair: we have an elevator for standard
                  wheelchairs [non-motorized] or for people with difficulty of
                  climbing stairs. The measurements of our elevator are as
                  follows: Elevator door: 70 CM. Internal dimension: 90CM X
                  90CM. */}
                  </MediumParagraph>
                </CheckboxContainer>
                <Spacer mt={{ xs: 8, md: 16 }} />
                <CheckboxContainer alignItems="flex-start">
                  <FlexContent justify="center" alignItems="center">
                    <CheckboxButton
                      onChange={(event) =>
                        setValue("vegan", event.target.checked)
                      }
                      type="checkbox"
                      name="vegan"
                      {...register("vegan")}
                    />
                    <CheckboxLabel />
                  </FlexContent>
                  <MediumParagraph color="black">
                    <FormattedMessage defaultMessage="Vegan menu" id="4bb5KE" />
                  </MediumParagraph>
                </CheckboxContainer>
                <Spacer mt={{ xs: 8, md: 40 }} />
              </>
            )}
          </>

          <MediumLabel fontWeight={700}>
            <FormattedMessage
              defaultMessage="Terms and conditions (mandatory)"
              id="7UMarG"
            />
          </MediumLabel>
          <Spacer mt={{ xs: 16, md: 24 }} />
          <CheckboxContainer alignItems="flex-start">
            <FlexContent justify="center" alignItems="center">
              <CheckboxButton
                onChange={(event) =>
                  setValue("termsConditions", event.target.checked)
                }
                type="checkbox"
                name="radio"
                {...register("termsConditions", { required: true })}
              />
              <CheckboxLabel />
            </FlexContent>
            <MediumParagraph color="black">
              <FormattedMessage defaultMessage="I accept the " id="MVn4MT" />
              <AdjustLink>
                <a href={getUrl()} target="_blank">
                  <FormattedMessage
                    defaultMessage="Terms and Conditions of Use. "
                    id="5GzJ25"
                  />
                </a>
              </AdjustLink>
              <FormattedMessage
                defaultMessage="Please note that additional fees may be applied by payment providers such as PayPal or banks"
                id="+PrzCu"
              />
            </MediumParagraph>
          </CheckboxContainer>

          {errors.termsConditions?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </FlexContent>
      </StyledForm>
    </>
  );
};
