import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { AuthContextType, LoginValues } from "./AuthProvider.types";
import { API_URL } from "env";

const auth = async (values: LoginValues) => {
  let token = null;
  try {
    const response = await axios.post(`${API_URL}/professionals/login`, values);
    token = response.data.token;
  } catch (error) {
    toast.error("Failed to login");
    console.error(error);
  }

  return token;
};

const defaultAuthContext = {
  token: "",
  isQr: false,
  paymentType: "",
  onLogin: () => {},
  onLogout: () => {},
};
export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const [isQr, setIsQr] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [paymentType, setPaymentType] = useState("");

  const validateToken = (token: string) => {
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      console.log("decodedToken", decodedToken);
      if (
        !decodedToken ||
        !decodedToken?.exp ||
        decodedToken?.exp * 1000 < Date.now()
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (token) {
      if (!validateToken(token)) {
        setToken("");
        localStorage.clear();
        navigate("/login");
        return;
      }
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setIsQr(decodedToken.isQr);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setPaymentType(decodedToken.paymentType);
      } else {
        localStorage.clear();
        navigate("/login");
        return;
      }
    }
  }, [token]);

  const handleLogin = async (values: LoginValues) => {
    // setIsLoading(true);
    const token = await auth(values);
    localStorage.setItem("token", token);
    setToken(token);
    navigate("/");
    // setIsLoading(false);
  };

  const handleLogout = () => {
    setToken("");
    setPaymentType("");
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const value = {
    isQr,
    token,
    paymentType,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
