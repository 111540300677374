import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../button/button";
import { FlexContent } from "../FlexContent/FlexContent";
import { Spacer } from "../spacer";
import { BigHeadline, BigParagraph } from "../typography.styles";

export const PaymentSuccess = () => {
  const { locale } = useIntl();
  const { isDuende } = useContext(BookingContext);

  return (
    <FlexContent
      direction="column"
      alignItems="center"
      justify="center"
      fullWidth
    >
      <BigHeadline>
        <FormattedMessage defaultMessage="Success! " id="jQjJ0W" />
      </BigHeadline>
      <Spacer mt={{ xs: 16, md: 24 }} />
      <BigParagraph textAlign="center">
        {isDuende ? (
          <FormattedMessage
            defaultMessage="If you do not receive an email confirmation
        in the next 15 minutes, your booking is not yet guaranteed, so please
        contact us urgently at +34 933 175 711 (office hours) or
        reservas@elduendebarcelona.com for any assistance.! "
            id="FEv2Wp"
          />
        ) : (
          <FormattedMessage
            defaultMessage="If you do not receive an email confirmation
        in the next 15 minutes, your booking is not yet guaranteed, so please
        contact us urgently at +34 933 175 711 (office hours) or
        tablao@tablaocordobes.com for any assistance.! "
            id="JMg6pd"
          />
        )}
      </BigParagraph>

      <Spacer mt={{ xs: 16, md: 24 }} />
      <Button
        onClick={() =>
          (window.location.href = locale === "en" ? "/" : `/${locale}`)
        }
      >
        <FormattedMessage
          defaultMessage="Go back to booking page"
          id="c3TpDY"
        />
      </Button>
    </FlexContent>
  );
};
