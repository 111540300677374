import { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { STEP_CALENDAR } from "../../../consts";
import { BookingContext } from "../../../providers/BookingProvider";
import { Footer } from "../../footer";
import { Header } from "../../Header/Header";
import { LangSwitcher } from "../../languageSwitcher";
import { Loader } from "../../loader";
import { PageHeadline, StyledLogotypes } from "../../typography.styles";
import {
  BookingContent,
  StyledBooking,
  StyledBookingContainer,
  StyledLogo,
} from "./styles";
import { FlexContent } from "components/FlexContent/FlexContent";
import { useAuth } from "providers/AuthProvider";

export const Booking = ({ navigate }) => {
  const { search } = useLocation();
  const { token, isQr } = useAuth();
  const { formatMessage } = useIntl();
  const { setAdultTickets, setStartDate, isLoading } =
    useContext(BookingContext);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(search));

    if (Object.keys(params).length !== 0) {
      if (
        params.date &&
        params.adults &&
        typeof Number(params.adults) === "number" &&
        typeof Number(params.child) === "number"
      ) {
        setStartDate(params.date);
        setAdultTickets(Number(params.adults));
        navigate({
          pathname: `/${STEP_CALENDAR}`,
          search,
        });
      }
    }

    navigate({
      pathname: "/",
      search,
    });
  }, []);

  if (!token) {
    navigate({
      pathname: "/login",
    });
  }

  return (
    <StyledBooking>
      <StyledBookingContainer>
        <Header backgroundImage={"/images/IMG_3968.jpeg"}>
          <>
            {!isQr && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    textTransform: "uppercase",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    localStorage.removeItem("token");
                    navigate({
                      pathname: "/login",
                    });
                  }}
                >
                  <FormattedMessage defaultMessage="logout" id="6BySgv" />
                </div>
              </>
            )}
            <FlexContent fullWidth direction="column" alignItems="center">
              <StyledLogotypes fullWidth justify="flex-start">
                <StyledLogo src={`/images/logo-tablao.svg`} alt="logo" />
                <StyledLogo src={`/images/logo-duende.png`} alt="logo" />
              </StyledLogotypes>
              <PageHeadline>
                {formatMessage({
                  defaultMessage:
                    "Book your tickets to the best flamenco show in Barcelona",
                  id: "07uB1s",
                })}
              </PageHeadline>
            </FlexContent>

            <LangSwitcher />
          </>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          {isLoading && <Loader />}
          <Outlet />
        </BookingContent>
      </StyledBookingContainer>
      <Footer />
    </StyledBooking>
  );
};
