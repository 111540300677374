import { useContext, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useReserveMutation } from "../../api/booking";
import { BookingContext } from "../../providers/BookingProvider";
import { PaymentsRedsys } from "../Payments/PaymentsRedsys";

export const FinalizeBooking = () => {
  const flagEnabled = false;
  const navigate = useNavigate();
  const {
    adultTickets,
    paymentMethod,
    formData,
    priceOption,
    setIsLoading,
    activeProduct,
    drinks,
    promoCode,
    isDuende,
  } = useContext(BookingContext);
  const { locale } = useIntl();
  const submitRef = useRef<HTMLInputElement>(null);
  const [submitReservation, { isSuccess, data: apiResponse }] =
    useReserveMutation();

  const handlePaypaypal = (url: string) => {
    window.location.href = url;
  };

  const sendReservation = () => {
    try {
      const data = {
        people: adultTickets,
        eventId: activeProduct.eventId,
        email: formData.email.trimEnd(),
        name: formData.name.trimEnd(),
        phone: formData.phone.trimEnd(),
        nationality: formData.nationality,
        languageCode: locale,
        comments: formData.comments.trimEnd(),
        wheelchair: formData.wheelchair ? "1" : "0",
        vegan: formData.vegan ? "1" : "0",
        paymentMethod,
        priceId: priceOption.priceId,
        location: isDuende ? "duende" : "tablao",
      };
      if (promoCode) {
        data["promoCode"] = promoCode;
      }
      if (formData.nif) {
        data["nif"] = formData.nif;
      }

      if (isDuende && flagEnabled) {
        data["drinks"] = Object.assign({}, ...drinks);
      }

      submitReservation(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (paymentMethod) {
      setIsLoading(true);
      sendReservation();
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (apiResponse && isSuccess) {
      if (apiResponse.hasOwnProperty("errorMessage")) {
        localStorage.removeItem("bookingData");
        navigate("/payment-error");
      }

      if (apiResponse.paymentInfo.method === "PAYPAL") {
        handlePaypaypal(apiResponse.paymentInfo.url || "");
      }

      if (apiResponse.paymentInfo.method === "CREDIT") {
        navigate("/payment-success");
      }

      if (apiResponse.paymentInfo.method === "REDSYS" && submitRef.current) {
        submitRef.current.click();
      }
    }
  }, [apiResponse, isSuccess]);

  useEffect(() => {
    if (apiResponse && isSuccess) {
      if (apiResponse.hasOwnProperty("errorMessage")) {
        navigate("/payment-error");
      }
    }
  }, [apiResponse, isSuccess]);

  return (
    <>
      {isSuccess && apiResponse.paymentInfo && apiResponse.paymentInfo.form && (
        <PaymentsRedsys
          ref={submitRef}
          signature={apiResponse.paymentInfo.form.Ds_Signature}
          signatureVersion={apiResponse.paymentInfo.form.Ds_SignatureVersion}
          merchantParameters={
            apiResponse.paymentInfo.form.Ds_MerchantParameters
          }
        />
      )}
    </>
  );
};
