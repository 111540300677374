import { PromoLabel } from "components/promoLabel";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FormattedMessage, useIntl } from "react-intl";
import { content } from "../../../i18n/content";
import { BookingContext } from "../../../providers/BookingProvider";
import { TimeTile } from "../../dayEvent";
import { FlexContent } from "../../FlexContent/FlexContent";
import { Spacer } from "../../spacer";
import { BigLabel, SmallHeadline } from "../../typography.styles";
import { ShowTitle } from "./Calendar.styles";
import {
  BookingElement,
  EventDetailsWrapper,
  EventPrice,
  LabelWrapper,
  MinimizedText,
  MinimizedTextMore,
  StyledLogo,
} from "./styles";

export const sortOrder = [
  "show_and_dinner",
  "show_and_tapas",
  "show_and_drink",
  "show_and_drink_b",
];

const getLowestPrice = (events) => {
  return Math.min(
    ...events.map((event) => {
      return event.prices[0].price;
    }),
  );
};

export const EventSelectionTablao = ({ calendar, activeProduct }) => {
  const { setActiveProduct, setPriceOption, setIsDuende } =
    useContext(BookingContext);
  const { locale } = useIntl();
  const [fullDescriptions, setFullDescriptions] = useState([]);

  const handleFullDescription = (eventType: string) => {
    if (fullDescriptions.includes(eventType)) {
      setFullDescriptions(
        fullDescriptions.filter((item) => item !== eventType),
      );
    } else {
      setFullDescriptions([...fullDescriptions, eventType]);
    }
  };

  const keys = Object.keys(calendar).filter((eventType) =>
    sortOrder.includes(eventType),
  );

  return (
    <>
      <BookingElement>
        <StyledLogo src={`/images/logo-tablao-dark.png`} alt="logo" />
        {keys.length === 0 ? (
          <div>
            <FormattedMessage
              defaultMessage="We are fully sold out"
              id="YdAj/k"
            />
          </div>
        ) : (
          <FlexContent direction="column">
            {keys
              .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
              .map((eventType) => {
                return (
                  <>
                    <BookingElement>
                      <LabelWrapper>
                        {eventType === "show_and_dinner" && (
                          <PromoLabel>
                            <FormattedMessage
                              defaultMessage="Best experience!"
                              id="13n9jb"
                            />
                          </PromoLabel>
                        )}
                      </LabelWrapper>

                      <ShowTitle direction="column">
                        <FlexContent>
                          <SmallHeadline isUppercase>
                            {content[locale][eventType]}
                          </SmallHeadline>
                        </FlexContent>
                        <Spacer mt={{ xs: 8 }} />
                        <MinimizedText
                          isFull={fullDescriptions.includes(eventType)}
                          onClick={() => handleFullDescription(eventType)}
                          style={{ maxWidth: 650 }}
                        >
                          {content[locale].descriptions[eventType]}
                        </MinimizedText>
                        <MinimizedTextMore
                          onClick={() => handleFullDescription(eventType)}
                        >
                          {fullDescriptions.includes(eventType) ? (
                            <FormattedMessage
                              defaultMessage="hide"
                              id="O4umPu"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="read more"
                              id="0lF7Yp"
                            />
                          )}
                        </MinimizedTextMore>
                      </ShowTitle>
                      <Spacer mt={{ xs: 12, md: 20 }} />
                      <EventDetailsWrapper
                        fullWidth
                        justify="space-between"
                        wrap
                      >
                        <FlexContent wrap>
                          {calendar[eventType]
                            .sort((a, b) => {
                              return dayjs(a.date).diff(dayjs(b.date));
                            })
                            .map((event) => {
                              return (
                                <>
                                  <TimeTile
                                    onClick={() => [
                                      setActiveProduct(event),
                                      setPriceOption(event.prices[0]),
                                      setIsDuende(false),
                                    ]}
                                    isActive={
                                      activeProduct
                                        ? event.eventId ===
                                          activeProduct.eventId
                                        : false
                                    }
                                    time={dayjs(event.date).format("HH:mm")}
                                    isPromo={event.prices[0].promo}
                                  />
                                </>
                              );
                            })}
                        </FlexContent>
                        {activeProduct &&
                        activeProduct.eventType === eventType ? (
                          <EventPrice
                            direction="column"
                            alignItems="flex-end"
                            isPromo={activeProduct.prices[0].promo}
                          >
                            <div style={{ fontSize: "12px" }}>
                              <FormattedMessage
                                defaultMessage="Price:"
                                id="7UHlfH"
                              />
                            </div>
                            <BigLabel fontWeight={700}>
                              {activeProduct.prices[0].price} euro
                            </BigLabel>
                          </EventPrice>
                        ) : (
                          <EventPrice
                            direction="column"
                            alignItems="flex-end"
                            isPromo={
                              activeProduct &&
                              activeProduct.eventType === eventType
                                ? activeProduct.prices[0].promo
                                : false
                            }
                          >
                            <div style={{ fontSize: "12px" }}>
                              <FormattedMessage
                                defaultMessage="Starts from:"
                                id="smf3BQ"
                              />
                            </div>
                            <BigLabel fontWeight={700}>
                              {getLowestPrice(calendar[eventType])} euro
                            </BigLabel>
                          </EventPrice>
                        )}
                      </EventDetailsWrapper>
                    </BookingElement>
                    <Spacer mt={{ xs: 16 }} />
                  </>
                );
              })}
          </FlexContent>
        )}
      </BookingElement>
    </>
  );
};
