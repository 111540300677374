import { FlexContent } from "../../FlexContent/FlexContent";
import {
  SmallHeadline,
  BigLabel,
  MediumParagraph,
  SectionHeadline,
} from "../../typography.styles";
import { TimeTile } from "../../dayEvent";
import {
  BookingElement,
  RadioButton,
  RadioButtonLabel,
  RadioButtonWrapper,
  StyledLogo,
  ZoneDescription,
} from "./styles";
import { content } from "../../../i18n/content";
import { PromoLabel } from "../../promoLabel";
import { colors } from "theme/colors";
import { Spacer } from "../../spacer";
import { BookingContext } from "../../../providers/BookingProvider";
import { useContext, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FormattedMessage, useIntl } from "react-intl";
import useScreenSize from "hooks/screenSize";
import {
  CalendarEvents,
  DesktopRadioWrapper,
  ElementWrapper,
  EventDetails,
  ShowPrice,
  ShowTitle,
} from "./Calendar.styles";
import { sortOrder } from "./EventSelectionTablao";

export const EventSelectionDuende = ({ calendar }) => {
  const keys = Object.keys(calendar).filter(
    (eventType) => !sortOrder.includes(eventType),
  );
  const [activeTime, setActiveTime] = useState<string>(
    keys.length > 0 ? keys[0] : null,
  );
  const { isMobile } = useScreenSize();
  const {
    setActiveProduct,
    activeProduct,
    setPriceOption,
    priceOption,
    setIsDuende,
  } = useContext(BookingContext);

  const { locale } = useIntl();

  const getZoneDescription = (zone: string) => {
    if ("zone A" === zone) {
      return (
        <ZoneDescription>
          <li>
            <FormattedMessage
              defaultMessage="Front and box seats "
              id="PhzChd"
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Complimentary drink"
              id="FVZdrs"
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Our top recommendation for the best view and experience!"
              id="+cxac1"
            />
          </li>
        </ZoneDescription>
      );
    }
    if ("zone B" === zone) {
      return (
        <ZoneDescription>
          <li>
            <FormattedMessage defaultMessage="Middle & side rows" id="H5UgjY" />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Complimentary drink"
              id="FVZdrs"
            />
          </li>
        </ZoneDescription>
      );
    }
    if ("zone C" === zone) {
      return (
        <ZoneDescription>
          <li>
            <FormattedMessage defaultMessage="End & back rows" id="y+ZTFJ" />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Complimentary drink"
              id="FVZdrs"
            />
          </li>
        </ZoneDescription>
      );
    }
    return "";
  };

  return (
    <BookingElement>
      <StyledLogo src={`/images/logo-duende-dark.png`} alt="logo" />
      {keys.length === 0 ? (
        <div>
          <FormattedMessage
            defaultMessage="We are fully sold out"
            id="YdAj/k"
          />
        </div>
      ) : (
        <div>
          <BigLabel fontWeight={700}>
            <FormattedMessage
              defaultMessage="Choose your preferred show time"
              id="FhBwyu"
            />
          </BigLabel>
          <Spacer mt={{ xs: 12 }} />
          <FlexContent fullWidth wrap>
            {keys
              .sort((a, b) => (a < b ? -1 : 1))
              .map((time) => (
                <TimeTile
                  onClick={() => [
                    setActiveTime(time),
                    setActiveProduct(calendar[time][0]),
                  ]}
                  isActive={
                    activeProduct
                      ? time === activeProduct.date.split(" ")[1]
                      : false
                  }
                  time={time}
                />
              ))}
          </FlexContent>
          <Spacer mt={{ xs: 32, md: 40 }} />
          <SectionHeadline>
            3. <FormattedMessage defaultMessage="Select seats" id="an7kvc" />
          </SectionHeadline>
          <p>
            <FormattedMessage
              defaultMessage="Select your preferred seating zone for an unforgettable flamenco
          experience!"
              id="2EEngM"
            />
          </p>
          <Spacer mt={{ xs: 16, md: 32 }} />
          <BookingElement>
            <CalendarEvents>
              {calendar[activeTime] && calendar[activeTime].length > 0 && (
                <>
                  {calendar[activeTime][0].prices.map((price, index) => (
                    <BookingElement
                      onClick={() => [
                        setActiveProduct(calendar[activeTime][0]),
                        setPriceOption(price),
                        setIsDuende(true),
                      ]}
                      style={{
                        borderColor:
                          priceOption && price.priceId === priceOption.priceId
                            ? colors.yellow
                            : colors.gray20,
                      }}
                    >
                      <EventDetails>
                        {isMobile && (
                          <FlexContent fullWidth>
                            {index === 0 && (
                              <>
                                <PromoLabel>
                                  <FormattedMessage
                                    defaultMessage="Best experience"
                                    id="R+N+r0"
                                  />
                                </PromoLabel>
                              </>
                            )}
                          </FlexContent>
                        )}
                        {!isMobile && (
                          <>
                            <FlexContent
                              fullWidth
                              justify={
                                index === 0 ? "space-between" : "flex-end"
                              }
                              alignItems="flex-start"
                            >
                              {index === 0 && (
                                <>
                                  <PromoLabel>
                                    <FormattedMessage
                                      defaultMessage="Best experience"
                                      id="R+N+r0"
                                    />
                                  </PromoLabel>
                                  <Spacer mt={{ xs: 12 }} />
                                </>
                              )}
                              <DesktopRadioWrapper>
                                <RadioButton
                                  type="radio"
                                  name="radio"
                                  value={price.priceId}
                                  checked={
                                    price.priceId === priceOption?.priceId
                                  }
                                  onChange={() => [
                                    setActiveProduct(calendar[activeTime][0]),
                                    setPriceOption(price),
                                    setIsDuende(true),
                                  ]}
                                />
                                <RadioButtonLabel />
                              </DesktopRadioWrapper>
                            </FlexContent>
                            <Spacer mt={{ xs: 16 }} />
                          </>
                        )}

                        <ShowTitle direction="column">
                          <Spacer mt={{ xs: 12 }} />
                          <SmallHeadline isUppercase>
                            {`${
                              content[locale][price.priceName.split(" ")[0]]
                            } ${price.priceName.split(" ")[1]}`}
                          </SmallHeadline>

                          <MediumParagraph>
                            {getZoneDescription(price.priceName)}
                          </MediumParagraph>
                        </ShowTitle>
                        <ElementWrapper>
                          <ShowPrice direction="column">
                            <BigLabel fontWeight={700}>
                              {price.price} euro
                            </BigLabel>
                          </ShowPrice>
                          {isMobile && (
                            <RadioButtonWrapper>
                              <RadioButton
                                type="radio"
                                name="radio"
                                value={price.priceId}
                                checked={price.priceId === priceOption?.priceId}
                                onChange={() => [
                                  setActiveProduct(calendar[activeTime][0]),
                                  setPriceOption(price),
                                  setIsDuende(true),
                                ]}
                              />
                              <RadioButtonLabel />
                            </RadioButtonWrapper>
                          )}
                        </ElementWrapper>
                      </EventDetails>
                    </BookingElement>
                  ))}
                </>
              )}
            </CalendarEvents>
          </BookingElement>
        </div>
      )}
    </BookingElement>
  );
};
