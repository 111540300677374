import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY } from "./env";
import "./global.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const options = {
  api_host: PUBLIC_POSTHOG_HOST,
};

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
